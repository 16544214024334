import { GET, parseId, uniqid } from "./common";


export default class ObjectList {

  /**
     * @var string
     */
  entity_type;


  /**
     * All data loaded
     *
     * @type {[]}
     */
  data = [];


  /**
     * This is the element where the data is displayed
     */
  $box;


  static APPEND = "append";
  static PREPEND = "prepend";
  static APPEND_IF_NOT_EXIST = "append_if_not_exist";
  static PREPEND_IF_NOT_EXIST = "prepend_if_not_exist";


  get row() {
    return $($(`#form-${this.entity_type}-row`).html());
  }


  $loader = $('.loader');


  /**
     *
     * @param data
     */
  postLoad(data) {
    this.displayCollection(data);
  }

  displayCollection(data) {
    this.getCollection(data).forEach((datum) => {
      this.addTemplate(datum);
    });
  }


  getCollection(data) {

    if (typeof data['hydra:member'] !== "undefined") {
      return data['hydra:member'];
    }

    if (typeof data.data !== "undefined") {
      return data.data;
    }

    return data;

  }



  addTemplate() {
  }


  /**
     *
     * @param url
     * @return {Promise<*>}
     */
  async fetchData(url, headers) {
    // Abort previous request
    if (this.req && this.req.abort) {
      this.req.abort();
    }

    return GET(url, null, headers, (req) => {
      this.req = req;
    });
  }


  /**
     *
     * @param template
     * @param prepend
     * @param existing @deprecated
     */
  insertTemplate(template, prepend, existing) {

    existing = this.inDom(template);

    switch (prepend) {
      case ObjectList.PREPEND:
        this.$box.prepend(template);
        break;
      case ObjectList.PREPEND_IF_NOT_EXIST:
        if (!existing) {
          this.$box.prepend(template);
        }
        break;
      case ObjectList.APPEND:
        this.$box.append(template);
        break;
      default:
        if (!existing) {
          this.$box.append(template);
        }
        break;
    }


  }


  /**
     * @param entity
     * @param prefix
     * @param container
     * @param row
     * @returns {*|jQuery|HTMLElement}
     */
  getTemplate(entity, prefix, container, row) {

    if (!prefix) {
      prefix = this.entity_type ? this.entity_type : "row";
    }

    if (!container) {
      container = this.$box;
    }

    let id;

    if (entity.tmpId) {
      id = entity.tmpId;
    } else {
      id = entity['@id'] ? parseId(entity['@id']) : entity.id;
    }

    if (!id) {
      id = uniqid();
    }

    id = prefix + '-' + id;

    let template = container.find(`#${id}`);


    if (template.length === 0) {
      template = row ? row : this.row;
    }

    template.attr('id', id);

    if (entity.id) {
      template.find('.bulk-update').attr('entity', entity.id);
    }

    return template;
  }


  /**
     *
     * @param data
     */
  removeData(data) {

    let index = this.getIndex(data);

    if (index !== -1) {
      this.data.splice(index, 1);
    }

  }


  /**
     *
     * @param data
     * @param $template
     */
  addData(data, $template) {

    if ($template) {
      data._template = $template;
    }

    this.addEntry(data);

  }

  addEntry(data, collection) {

    let index = this.getIndex(data, collection);

    if (index !== -1) {
      if (collection) {
        collection[index] = data;
      } else {
        this.data[index] = data;
      }
    } else {
      if (collection) {
        collection.push(data);
      } else {
        this.data.push(data);
      }
    }
  }


  removeEntry(data, collection) {
    let index = this.getIndex(data, collection);

    if (index === -1) {
      return;
    }


    if (collection) {
      collection.splice(index, 1);
    } else {
      this.data.splice(index, 1);
    }
  }


  getEntry(data, collection) {
    const index = this.getIndex(data, collection);

    if (index === -1) {
      return undefined;
    }

    return collection ? collection[data] : this.data[index];
  }

  /**
     *
     * Gets the index of an entity inside the list of elements
     *
     * @param data
     * @param collection array | undefined
     * @return {number}
     */
  getIndex(data, collection) {

    if (!collection) {
      collection = this.data;
    }


    let index = -1;
    if (data['@id']) {
      collection.forEach((el, k) => {
        if (el['@id'] === data['@id']) {
          index = k;
        }
      });
    } else {
      index = collection.indexOf(data);
    }
    return index;
  }


  /**
     *
     * @param $element
     * @return {boolean}
     */
  inDom($element) {
    return $element.parents('html').length !== 0;
  }


}
