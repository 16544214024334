import amplitude from 'amplitude-js';
export const getAmplitudeRole = (role) => {

  const mapping = {
    'ROLE_MEDECIN' : 'doctor',
    'ROLE_MEDECIN_PENDING': 'doctor_pending',
    'ROLE_PATIENT' : 'patient',
    'ROLE_INFIRMIERE' : 'nurse',
    'ROLE_ADMIN' : 'admin',
    'ROLE_HOSPITAL_ADMIN' : 'hospital_admin',
    'ROLE_DEVELOPER' : 'developer',
    'ROLE_MEDICAL_COORDINATOR' : 'coordinator',
    'ROLE_INSTITUTION_MANAGER' : 'institution_manager',
    'ROLE_SUPPORT' : 'support',
    'ROLE_RESEARCH' : 'research',
    // @deprecated
    'ROLE_ASSISTANT' : 'assistant'
  };

  return mapping[role] || '';

};


export const EVENT_TYPES = {
  UI: {
    MODAL_DISMISSED: 'UI_MODAL_DISMISSED',
    BUTTON_CLICKED: 'UI_BUTTON_CLICKED',
    PAGE_VIEWED: 'UI_PAGE_VIEWED',
    ELEMENT_SCROLLED: 'UI_ELEMENT_SCROLLED'
  }
};


export const initAmplitude = () => {

  // eslint-disable-next-line no-undef
  amplitude.getInstance().init(amplitude_api_key,null,{
    apiEndpoint: 'api.eu.amplitude.com'
  });
  amplitude.getInstance().setUserId(current_user.id);
  amplitude.getInstance().setUserProperties({
    role: getAmplitudeRole(current_user.role),
    institution: current_user.institution,
  });

  let $title = document.querySelector('title');

  let title = $title.innerText.trim();

  let pii = $title.getAttribute('data-pii');

  if(pii) {
    title = title.replace(pii,'*****');
  }

  amplitude.getInstance().logEvent('Page View', { page : title });

};

const track = (eventType, properties) => {

  if (!properties) {
    properties = {};
  }

  properties.page = window.location.pathname.replace(/[0-9]/g, ':id');

  /*
    return POST(Routing.generate('api_amplitude_post_record'), {
        "eventType": eventType,
        "eventProperties": properties
    }, null, null, null, {
        silently: true
    })*/

};

export default track;
