import { DELETE, displaySuccess, ts, viewport } from "./common";
import ObjectList from "./object-list";
import track, { EVENT_TYPES } from "./amplitude";
import { trans, USER_HELPER_DELETE_SELECTION,COMMON_ACTIONS_LOAD_MORE } from "../../translator";

export default class EntityList extends ObjectList {

  $bulkUpdate = $('#bulk-update');
  $bulkDelete = $('#bulk-delete');
  $bulkUpdatePopup = $('#bulk-update-popup');
  $addButton = $('#drawer-list-add');
  hasPagination = true;

  /**
     *
     * @param value
     */
  set loading(value) {
    if (value) {
      this.showLoader();
    } else {
      this.hideLoader();
    }
  }

  get loaderContainer() {
    return this.scrollableElement.find('.loader-container');
  }

  /**
     * This is the form used to filter the data
     */
  $filterForm;


  /**
     * Table order
     */
  $tableOrder;


  scrollReverse = false;


  fullScreenScroll = false;


  /**
     * The current page
     *
     * @type {number}
     */
  page = 1;

  /**
     * If the data is loading
     *
     * @type {boolean}
     */
  isLoading = false;

  /**
     * The max results
     *
     * @type {number}
     */
  maxResults = 999;


  params = {};

  order = 'DESC';
  orderBy = "lastLogin";


  /**
     * Returns the "not found" panel to display
     *
     * @return {string}
     */
  get notFoundPanel() {
    return $($('#not-found-panel').html());
  }

  /**
     *
     * @return {*}
     */
  get scrollableElement() {
    return this.$box;
  }

  get $bulkSelect() {
    return this.scrollableElement.find('thead input[type="checkbox"]');
  }

  req = null;


  get row() {
    return $($('#table-row').html());
  }


  onBulkCheck() {

    this.$bulkUpdate.toggle(this.hasBulkSelected());
    this.$bulkDelete.toggle(this.hasBulkSelected());

  }

  /**
     *
     * @return {*}
     */
  getSelectedCheckboxes() {
    return this.$box.find(".bulk-update:not(':disabled'):checked");
  }

  resetCheckboxes() {
    this.getSelectedCheckboxes().prop('checked', false);
  }

  getSelectedEntitiesIds() {
    let checkboxes = this.getSelectedCheckboxes();

    const entities = [];

    checkboxes.each((k, box) => {
      entities.push($(box).attr('entity'));
    });

    return entities;
  }

  getSelectedEntities() {
    const ids = this.getSelectedEntitiesIds();

    return this.data.filter((d) => {
      return ids.includes(d.id);
    });

  }



  /**
     *
     */
  hasBulkSelected() {
    return this.$box.find('.bulk-update:checked').length !== 0;
  }


  registerEvents() {

    if (this.$filterForm) {
      this.$filterForm.on('submit', (e) => {
        e.preventDefault();
        this.loadData(true);
      });
    }

    if (this.hasPagination) {
      // Infinite scroll
      this.scrollableElement.off('scroll').on('scroll', () => {
        this.onScroll();
      });
    }

    if (this.fullScreenScroll) {
      this.fitToScreen();
      $(window).on('resize', () => {
        this.fitToScreen();
      });
    }


    if (this.$addButton) {
      this.$addButton.on('click', (e) => {
        this.onAddButton(e);
      });
    }

    this.$bulkUpdate.on('click', (e) => {
      this.onBulkUpdate(e);
    });

    this.$bulkDelete.on('click', (e) => {
      this.onBulkDelete(e);
    });


    this.$bulkSelect.off('change').on('change', (e) => {

      let isChecked = $(e.target).prop('checked');

      this.scrollableElement.find(".bulk-update:not(':disabled')").prop('checked', isChecked);
      this.onBulkCheck();

    });


    if (this.$tableOrder) {

      this.$tableOrder.on('click', (e) => {
        let prop = $(e.target).attr('prop');
        let _order = $(e.target).attr('order');

        this.orderBy = prop;
        this.order = _order;

        this.$tableOrder.each((elem) => {
          $(elem).attr('order', $(elem).attr('default'));

        });

        $(e.target).attr('order', _order === 'ASC' ? 'DESC' : 'ASC');

        this.loadData(true);

      });
    }

  }


  fitToScreen() {
    // 50 = padding;
    const height = viewport().height - this.scrollableElement.offset().top - 50;
    this.scrollableElement.css('max-height', height);
  }


  /**
     *
     * @param $template
     * @param element
     */
  addTemplateEvents($template) {

    $template.find('.bulk-update').off('click').on('change', () => {
      this.onBulkCheck();
    });


    const $parent = $template.find('.dropdown').parent();

    $parent.off('shown.bs.dropdown').on('shown.bs.dropdown', (e) => {

      let $elem = $(e.relatedTarget);

      let $tr = $elem.closest('tr');

      let $menu = $parent.find('.dropdown-menu');

      if ($menu.length) {
        if (!this.isElementIntoView($menu)) {
          this.scrollToElement($tr, 300);
        }
      }
      $tr.addClass('selected');

    });


    $parent.off('hide.bs.dropdown').on('hide.bs.dropdown', (e) => {
      let $elem = $(e.currentTarget);
      let $tr = $elem.closest('tr');
      $tr.removeClass('selected');
    });


  }


  async bulkDelete(route) {

    let checkboxs = this.getSelectedCheckboxes();

    let ids = [];
    checkboxs.each((k, d) => {
      ids.push($(d).attr('entity'));
    });


    if (ids.length) {

      let valid = confirm(trans(USER_HELPER_DELETE_SELECTION, {}, 'user'));

      if (!valid) {
        return;
      }

      this.showLoader();

      const docs = await DELETE(Routing.generate(route), {
        ids: ids,
      });

      docs.deleted.forEach((id) => {
        const $template = this.getTemplate({ id: id });
        if ($template) {
          $template.remove();
        }
      });

    }

    checkboxs.prop('checked', false);
    this.$bulkSelect.prop('checked', false);
    this.onBulkCheck();

    this.hideLoader();

    displaySuccess(trans(USER_HELPER_DELETE_SELECTION, {}, 'user'));


  }


  /**
     *
     * @deprecated
     *
     * @param e
     */
  onBulkDelete() {
  }

  onBulkUpdate() {
  }

  onAddButton() {
  }


  /**
     *
     * @return {*}
     */
  getLastData() {
    let index = this.data.length;

    return this.data[index - 1];
  }

  displayNotFoundPanel(hide) {

    // Always remove the panel before attempting to add it
    this.$box.find('.item-not-found-panel').remove();
    this.$box.removeClass('empty');

    if (this.data.length === 0 && !hide) {
      let panel = this.notFoundPanel;
      panel.addClass('item-not-found-panel');
      this.$box.append(panel);
      this.$box.addClass('empty');
      this.$box.show();
    }
  }


  /**
     *
     * @param reset
     * @param silently
     * @return {Promise<void>}
     */
  async loadData(reset, silently) {
    if (reset) {
      this.page = 1;
      this.maxResults = 999;
      this.data = [];
      this.$box.find('.data').remove();
      this.$box.find('.details').remove();
      this.$box.find('.not-found').remove();
      if (!silently) {
        this.showLoader();
      }
    }

    this.isLoading = true;
  }


  /**
     * Called on scroll on the element
     */
  onScroll() {

    let toLoad = this.scrollReverse ?
      this.scrollableElement[0].scrollTop <= 250 :
      this.scrollableElement[0].scrollTop + this.scrollableElement[0].clientHeight >=
            (this.scrollableElement[0].scrollHeight - 250);

    if (toLoad) {

      this.loadNextPage();

      track(EVENT_TYPES.UI.ELEMENT_SCROLLED, {
        element: this.entity_type,
        pageNumber: this.page
      });

    }
  }

  loadNextPage() {

    // Do not search if you have reached the end
    if (this.data.length >= this.maxResults) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.page++;

    this.showPaginationLoader();

    this.loadData();
  }


  showPaginationLoader() {
  }

  hidePaginationLoader() {
  }

  /**
     *
     * @param $template
     * @param entity
     */
  async onDelete($template, entity) {

    let c = confirm(ts(`${this.entity_type}.list.delete.confirm`));

    if (!c) {
      return;
    }

    this.showLoader();

    await DELETE(entity['@id']);

    $template.remove();

    this.hideLoader();

    displaySuccess(ts(`${this.entity_type}.list.delete.success`));

    this.removeData(entity);
    this.displayNotFoundPanel();

  }


  getQuery() {
    let query = {
      page: this.page,
    };

    if (this.orderBy) {
      let _order = {};
      _order[this.orderBy] = this.order;
      query._order = _order;
    }

    return query;

  }


  /**
     *
     * @param data
     * @param silently
     */
  postLoad(data, silently) {

    super.postLoad(data);

    if (data && this.getCollection(data).length === 0) {
      this.maxResults = this.data.length;
    }

    if (!silently) {
      this.displayNotFoundPanel();
    }

    this.handleLoadMoreButton();

    this.hideLoader();

  }

  handleLoadMoreButton() {

    this.scrollableElement.parent().find('.load-more').remove();

    if (this.fullScreenScroll && this.maxResults > this.data.length && !this.hasScrollBar()) {
      this.addLoadMoreButton();
    }
  }

  hasScrollBar() {
    return this.scrollableElement[0].scrollHeight > this.scrollableElement[0].clientHeight;
  }

  addLoadMoreButton() {
    let $button = $(`<button class="btn btn-secondary iblock mg-auto load-more">
${trans(COMMON_ACTIONS_LOAD_MORE, [], 'common')}</button>`);
    this.scrollableElement.after($button);
    $button.on('click', () => {
      this.loadNextPage();
    });
  }


  /**
     *
     * @param element
     * @return {string}
     */
  addTemplate(element) {
    this.addData(element);

    return "";
  }


  /**
     *
     */
  showLoader() {
    this.$loader.show();
    this.$box.find('tr').hide();
    if (this.loaderContainer) {
      this.loaderContainer.show();
    }

    this.$box.closest('table').find('th').css('opacity', 0);

    this.isLoading = true;
    if (this.$filterForm) {
      this.$filterForm.find('input,select,button,textarea').prop('disabled', true);
    }
  }

  /**
     *
     */
  hideLoader() {
    this.$box.find('tr').show();
    if (this.loaderContainer) {
      this.loaderContainer.hide();
    }
    this.$loader.hide();

    this.$box.closest('table').find('th').css('opacity', 1);

    this.isLoading = false;
    if (this.$filterForm) {
      this.$filterForm.find('input,select,button,textarea').prop('disabled', false);
    }

    this.hidePaginationLoader();

  }


  scrollToBottom() {
    this.scrollableElement.scrollTop(this.$box[0].scrollHeight);
  }


  scrollToElement(element, animation) {
    if (!animation) {
      animation = 0;
    }

    let offsetTop;
    if (this.scrollReverse) {
      offsetTop = element[0].offsetTop;
    } else {
      offsetTop = element[0].offsetTop - (element.height() * 2);
    }

    this.scrollableElement.animate({ scrollTop: offsetTop }, animation);

  }

  scrollToTop() {
    this.scrollableElement.scrollTop(0);
  }

  /**
     *
     * @from https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
     *
     * @param $elem
     * @return {boolean}
     */
  isElementIntoView($elem) {
    const docViewTop = this.scrollableElement.offset().top;
    const docViewBottom = docViewTop + this.scrollableElement.height();

    const elemTop = $elem.offset().top;
    const elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

}
